import type { SiteState as ApiSiteState } from "@/api/models/site-state.model";
import type { SiteState as StoreSiteState } from "@/store/models/site-state.model";

export function mapApiSiteStateToStoreSiteState(apiSiteState: ApiSiteState): StoreSiteState {
  return {
    publicMode: apiSiteState.public_mode,
    messageForPublic: apiSiteState.message_for_public,
    moderationMode: apiSiteState.moderation_mode,
    messageForModerators: apiSiteState.message_for_moderators,
    stage: apiSiteState.stage,
    frontendVer: apiSiteState.frontend_ver,
    tileLayerUrl: apiSiteState.tile_layer_url,
    frontendVerBuild: '1.0.2b',
  };
}
