<template>
  <MDBModal
      class="modal fade"
      tabindex="-1"
      v-model="modal"
  >
    <MDBModalHeader>
      <MDBModalTitle> {{ $t("home.howItWorks") }} </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <img src="/horizontal-logo.svg" class="img-fluid" alt="logo">
      <p class="lead">
        Для фиксации нарушений через приложение ХрюХрюКар, вам необходимо:
      </p>
      <ul class="list-unstyled">
        <li>
          <i class="fas fa-sign-in-alt me-2 text-success"/>
          Авторизоваться через аккаунт Google или VK;
        </li>
        <li>
          <i class="fas fa-camera me-2 text-success"/>
          Перейти в раздел "Охота";
        </li>
        <li>
          <i class="fas fa-location-arrow me-2 text-success"/>
          Включить геолокацию и Wi-Fi (для точности определения местоположения);
        </li>
        <li>
          <i class="fas fa-crosshairs me-2 text-success"/>
          Нажать на кнопку "Начать охоту";
        </li>
        <li>
          <i class="fas fa-shield-alt me-2 text-success"/>
          Предоставить доступ к камере и местоположению;
        </li>
        <li>
          <i class="fas fa-list me-2 text-success"/>
          Сверху в меню выбрать тип нарушения (по-умолчанию выбрано "Стоянка на газоне");
        </li>
        <li>
          <i class="fas fa-car-crash me-2 text-success"/>
          Сфотографировать нарушение так, чтобы номер был различим, автомобиль вписывался в границы фотографии и были видны объекты, позволяющие точно определить местоположение (опоры освещения, деревья, части зданий и т.п.);
        </li>
        <li>
          <i class="fas fa-clock me-2 text-success"/>
          Дождаться распознавания номера и геокодирования местоположения;
        </li>
        <li>
          <i class="fas fa-check-double me-2 text-success"/>
          Нажать на кнопку "Отправить" (зеленая галочка);
        </li>
      </ul>
      <hr>
      <figure>
        <blockquote class="blockquote">
          <p>
            Если после распознавания в номерах будут ошибки, не стоит беспокоиться, модераторы всё проверят и исправят.
          </p>
        </blockquote>
        <figcaption class="blockquote-footer">
          Лишние автомобильные номера Вы можете удалить, нажав на крестик рядом с номером.
        </figcaption>
      </figure>
      <hr>
      <p class="lead">
        Насколько это безопасно?
      </p>
      <p>
        Авторов фотографий не видят другие пользователи и даже модераторы! Также мы не показываем точное время фотографирования,
        для исключения возможности преследования авторов фотографий. Если в процессе модерации будет обнаружено, что на фотографии
        имеются детали, позволяющие идентифицировать автора, фотография будет удалена.
      </p>
      <hr>
      <p class="lead">
        Что происходит с фотографиями?
      </p>
      <p>
        При проверке модераторы либо подтверждают нарушение, либо отклоняют его. В случае подтверждения нарушения,
        модератор <mark>от своего имени</mark> формирует заявление на нарушителя и отправляет его в соответствующие органы.
        Если нарушение не подтверждено, фотография снимается с публикации, в результате чего её видит только автор и администраторы системы.
      </p>
      <hr>
      <p class="lead">
        Что я увижу на карте?
      </p>
      <p>
        Когда вы авторизованы, ваши фотографии подсвечиваются на карте с использованием разных цветов в зависимости от статуса модерации:
        <span class="text-success">зеленый</span> - подтверждено, <span class="text-danger">красный</span> - отклонено, <span class="text-primary">синий</span> - на модерации,
        <mark>черный</mark> - подтвержденные нарушения других пользователей.
      </p>
      <p>
        По любому из нарушений можно кликнуть и увидеть детали: фотографию, номер, дату, местоположение и в отдельных
        случаях - панораму места съемки. Также в окне деталей можно увидеть статус модерации. Если нарушение подтверждено,
        это означает что заявление уже отправлено в соответствующие органы, а если отклонено, то фотография снята с публикации
        (причину отклонения вы можете увидеть в окне деталей, нажав на <span><i class="fas fa-info-circle ms-1 me-1 text-primary"></i></span> возле статуса).
        В заголовке окна вы можете воспользоваться кнопкой для копирования уникальной ссылки на нарушение, только не забудьте, что
        у других пользователей оно откроется только после публикации.
      </p>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="success" @click="modal = false">
        {{ $t("home.letsGo") }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
  <MDBSideNav v-model="sideNavOpen" :backdrop="false" class="side-bar">
    <MDBSideNavMenu scrollContainer ref="sideNav">
      <MDBSideNavItem class="mt-1">
        <MDBSideNavLink @click="ifaceStore.setSideNavOpen(false); modal = true" newTab>
          <i class="fas fa-question-circle me-4"></i>
          {{ $t("home.howItWorks") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <hr class="m-1">
      <MDBSideNavItem>
        <MDBSideNavLink :to="HOME_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="map-marked-alt" class="me-4"/>
          {{ $t("content.map") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="authStore.isAuthenticated">
        <MDBSideNavLink :to="HUNTING_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="chart-line" class="me-4"/>
          {{ $t("content.stats") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="authStore.user?.isModerator || authStore.user?.isSuperuser">
        <MDBSideNavLink :to="MODERATOR_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="tachometer-alt" class="me-4"/>
          {{ $t("profile.console") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="authStore.isAuthenticated">
        <MDBSideNavLink @click="logOut(); ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="sign-out-alt" class="me-4"/>
          {{ $t("profile.exit") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-else>
        <MDBSideNavLink :to="SIGN_IN_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="sign-out-alt" class="me-4"/>
          {{ $t("content.signIn") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <hr class="m-1">
      <MDBSideNavItem>
        <MDBSideNavLink :href="URL_OF_TELEGRAM" @click="ifaceStore.setSideNavOpen(false)" newTab>
          <i class="fab fa-telegram-plane me-4"></i>
          {{ $t("home.tgChannel") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :href="URL_OF_VK" @click="ifaceStore.setSideNavOpen(false)" newTab>
          <i class="fab fa-vk me-4"></i>
          {{ $t("home.vkGroup") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :to="MANIFESTO_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="bullhorn" class="me-4"/>
          {{ $t("content.manifesto") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <hr class="m-1">
      <MDBSideNavItem>
        <MDBSideNavLink :href="URL_OF_FORM_TO_ADD_PLACE" @click="ifaceStore.setSideNavOpen(false)" newTab>
          <MDBIcon fas icon="city" class="me-4"/>
          {{ $t("home.addCity") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :href="URL_OF_FORM_TO_ASK_EVERYTHING" @click="ifaceStore.setSideNavOpen(false)" newTab>
          <MDBIcon fas icon="envelope" class="me-4"/>
          {{ $t("home.askQuestion") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <hr class="m-1">
      <MDBSideNavItem>
        <MDBSideNavLink :to="PRIVACY_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="user-secret" class="me-4"/>
          {{ $t("footer.privacy") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :to="RULES_PATH" @click="ifaceStore.setSideNavOpen(false)">
          <MDBIcon fas icon="gavel" class="me-4"/>
          {{ $t("footer.rules") }}
        </MDBSideNavLink>
      </MDBSideNavItem>
      <hr class="m-1 mb-2">
      <MDBSideNavItem class="hstack mb-2">
        <LocaleSelectorContainer class="locale-dropdown" />
      </MDBSideNavItem>
      <hr class="m-1 mb-2 mt-2">
      <MDBSideNavItem>
        <MDBSideNavLink :href="versionLink" target="_self">
          <strong class="text-muted fw-lighter"> {{ $t("application.version") }} {{ versionNumber }} {{ versionNumber != buildNumber ? $t("application.outdated") : ''}}</strong>
        </MDBSideNavLink>
      </MDBSideNavItem>
    </MDBSideNavMenu>
  </MDBSideNav>
  <div class='toggle-wrapper d-flex justify-content-between'>
    <MDBBtn
        class="btn btn-dark btn-lg btn-floating"
        type="button"
        @click="ifaceStore.toggleSideNav"
        v-if="buttonVisible"
    >
      <MDBIcon class="fas fa-bars"/>
    </MDBBtn>
    <MDBBtn
        class="btn btn-secondary btn-lg btn-floating"
        type="button"
        @click="$router.push({ path: HOME_PATH })"
        v-if="buttonVisible && $route.path !== HOME_PATH"
    >
      <MDBIcon class="fas fa-map-marked-alt"/>
    </MDBBtn>
  </div>
</template>
<script lang="ts">
import {useIfaceStore} from '@/store/iface';
import {MDBBtn, MDBAnimation, MDBIcon, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter} from 'mdb-vue-ui-kit';
import LocaleSelectorContainer from '@/ui/containers/LocaleSelectorContainer.vue'
import {useAuthStore} from '@/store/auth';
import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import {
  HOME_PATH,
  MANIFESTO_PATH,
  HUNTING_PATH,
  HUNT_ROUTE_NAME,
  ERROR_AUTH_ROUTE_NAME,
  MODERATOR_PATH,
  SIGN_IN_PATH,
  URL_OF_FORM_TO_ADD_PLACE,
  URL_OF_FORM_TO_ASK_EVERYTHING,
  URL_OF_TELEGRAM,
  URL_OF_VK,
  PRIVACY_PATH,
  RULES_PATH,
  MODERATOR_ROUTE_NAME,
} from "@/helpers/constants/routes";
import {useAppStore} from "@/store/app";


export default defineComponent({
  name: "SideNavContainer",
  components: {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBIcon,
    MDBBtn,
    LocaleSelectorContainer,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBAnimation,
  },
  data() {
    return {
      HOME_PATH,
      MANIFESTO_PATH,
      HUNTING_PATH,
      MODERATOR_PATH,
      SIGN_IN_PATH,
      URL_OF_FORM_TO_ADD_PLACE,
      URL_OF_FORM_TO_ASK_EVERYTHING,
      URL_OF_TELEGRAM,
      URL_OF_VK,
      PRIVACY_PATH,
      RULES_PATH,
      HUNT_ROUTE_NAME,
      ERROR_AUTH_ROUTE_NAME,
      MODERATOR_ROUTE_NAME,
    };
  },
  methods: {
    logOut(){
      this.$router.push({ path: HOME_PATH });
      this.authStore.logoutUser();
    },
  },
  computed: {
    versionNumber(): string {
      return `${this.appStore.siteState.frontendVer}`;
    },
    buildNumber(): string {
      return `${this.appStore.siteState.frontendVerBuild}`;
    },
    versionLink(): string {
      return `${window.location.origin}${this.$route.path}?version=${this.appStore.siteState.frontendVer}`
    },
    sideNavOpen: {
      get(): boolean {
        return this.ifaceStore.isSideNavOpen;
      },
      set(value: boolean) {
        this.ifaceStore.setSideNavOpen(value);
      },
    },
    buttonVisible(): boolean {
      return this.$route.name !== this.HUNT_ROUTE_NAME && this.$route.name !== this.ERROR_AUTH_ROUTE_NAME && this.$route.name !== MODERATOR_ROUTE_NAME;
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const authStore = useAuthStore();
    const appStore =  useAppStore();
    const sideNav = ref<HTMLElement | null>(null);
    // @ts-ignore
    onClickOutside(sideNav, event => ifaceStore.setSideNavOpen(false));
    const modal = ref(false);
    const animateToggler = ref(false);
    setInterval(() => {
      animateToggler.value = !animateToggler.value;
    }, 5000);
    return {
      ifaceStore,
      authStore,
      sideNav,
      modal,
      animateToggler,
      appStore,
    }
  },
});
</script>
<style lang="scss" scoped>

.toggle-wrapper {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  width: calc(100% - 20px);
  z-index: 200;
}

.locale-dropdown {
  margin-left: auto;
  margin-right: auto;
  ::after {
    margin-left: 0.65rem !important;
    margin-right: 0.65rem !important;
    vertical-align: 0.15em !important;
  }
}

</style>
