const hunting = {
  pleaseWait: 'Минуточку, пожалуйста!',
  toAchieveTheExpectedResult: 'Чтобы добиться ожидаемого результата,',
  pleaseTakeAFewMinutes: 'просим уделить пару минут, чтобы  ознакомиться с основными правилами. За нарушение некоторых правил, к сожалению, иногда приходится',
  blockUsers: 'блокировать пользователей.',
  next: 'Далее',
  back: 'Назад',
  preparationOfTheDeviceAndPermissions: 'Подготовка устройства и разрешения',
  forHuntingOffenders: 'Для фиксации нарушений,',
  theOinkOinkCarWillRequestTheFollowingPermissions: 'ХрюХрюКар запросит следующие разрешения:',
  location: 'Местоположение',
  determineTheAddressOfTheViolation: 'определяем адрес нарушения',
  camera: 'Доступ к камере',
  fixTheViolation: 'фиксируем нарушение',
  doNotForgetToEnableGeolocation: 'Не забудьте, пожалуйста, включить геолокацию и WiFi (просто включите, для повышения точности)!',
  basicRequirements: 'Основные требования',
  noFaces: 'На фотографии не должно быть видно лиц;',
  theCarMustCompletelyFit: 'Автомобиль должен полностью вписываться в границы фотографий;',
  theLicensePlateMustBeClearlyReadable: 'Номер автомобиля должен быть четко читаем;',
  theViolationTypeMustBeUnambiguouslyConfirmed: 'Тип нарушения должен однозначно подтверждаться фотографией (должны быть видны необходимые детали - отсутствие твердого покрытия, наличие пешеходного перехода, тротуара, бордюра и прочее);',
  oneViolationCanBeFixedOnlyOnceADay: 'Одно нарушение может фиксироваться только один раз в 24 часа. Но спустя сутки можете фиксировать повторно =)',
  aboutSafety: 'О безопасности',
  whenHuntingForOffenders: 'Когда боретесь с нарушениями,',
  knowThatYouAreDoingTheRightThingBut: 'знайте, что вы занимаетесь правым делом, но',
  takeCareOfYourOwnSafety: 'позаботьтесь о собственной безопасности!',
  modernSocietyInRussiaHasAKindOfMentality: 'Современное общество в России имеет своеобразный менталитет и далеко не всегда способно воспринимать критику. Исходя из этого, если вы видите, что нарушитель вас заметил и начинает вести себя агрессивно,',
  doNotRiskYourHealthAndLife: 'не стоит рисковать своим здоровьем и жизнью.',
  inThisCaseItIsBetterToJustLeave: 'В таком случае, лучше просто уйти и не рисковать. Но вы всегда можете вернуться и зафиксировать нарушение с другого ракурса, если это возможно.',
  whatYouDefinitelyShouldNotDo: 'Чего точно не стоит делать - это пытаться вразумить нарушителя.',
  ourCustomersUnderstandOnlyTheLanguageOfFines: 'Наши "клиенты" понимают только язык штрафов. Если фиксировать нарушения в течении двух недель, то вы можете быть уверены, что нарушитель начнет мыслить иначе, получив серию штрафов и знатно пополнив бюджет.',
  selfControlAndModeration: 'Самоконтроль и модерация',
  allPhotosMustGoThroughTheModerationProcedure: 'Все фотографии обязательно проходят процедуру модерации,',
  butWeStillAskYouToApproachTheIssueOfContentQualityResponsibly: 'но мы все же просим вас ответственно подходить к вопросу качества контента, ведь все лица, занятые в данном проекте, тратят свои ресурсы на безвозмездной основе, в том числе наши модераторы. Механизмы ХрюХрюКара позволяют вам в течении суток удалить фотографию, нарушение или распознанный номер автомобиля, за исключением случаев, когда указанные материалы уже прошли модерацию.',
  usersSendingPoorQualityContentMayBeBlocked: 'Пользователи, присылающие некачественный контент, могут быть заблокированы.',
  huntingStart: 'Начало охоты',
  letSStart: 'Начнем!',
  yourSuccessForTheMonth: 'Ваши успехи за месяц:',
  violationsFixed: 'Нарушений зафиксировано',
  violationsConfirmed: 'Нарушений подтверждено',
  notPassedModeration: 'Не прошло модерацию',
  goHunt: 'На охоту!',
  your_catch: 'Ваш улов',
  verifiedByModerator: 'проверено модератором',
  processing: 'Обработка...',
  loading: 'Загрузка камеры...',
  uploading_wait: 'Загрузка на сервер, ждите...',
  license_plate_recognition: 'Распознавание номеров',
  license_plates_recognized: 'Номера распознаны',
  geocoding: 'геокодирование...',
  geocoded: 'геокодировано.',
  loadingResults: 'Загрузка результатов...',
  neuralNetworkDidNotDetectLicensePlates: 'Нейросеть не обнаружила автомобильные номера на фотографии. Выберите другой ракурс и попробуйте еще раз.',
  carPlateRemoved: "Автомобильный номер {plate} удален",
  allCarPlatesWasRemoved: "Все автомобильные номера были удалены, фотография будет также удалена. Сделайте новый снимок, если это необходимо.",
  photoWasSentCheckItAndContinue: "Фотография отправляется. Убедитесь что фото не размыто. Зелёная кнопка - подтвердить и перейти к следующему авто, красная - удалить и переснять.",
  unableToFindASuitableCamera: "Нам не удалось найти подходящую камеру, вы предоставили разрешение? При повторении ошибки, очистите кэш браузера или обратитесь к разработчику.",
  photoHasBeenAccepted: "Фотография принята, после модерации она появится на карте и заявление будет отправлено. Спасибо!",
  photoHasBeenDeleted: "Фотография удалена, зафиксируйте нарушение снова.",
  photoRejectedBecauseAlreadyExists: "Фотография отклонена, так как нарушение уже фиксировалось сегодня. Похоже на то, что кто-то тут уже проходил, смените локацию, пожалуйста. Чтобы продолжить, удалите фотографию.",
  violationTypesNotFoundHeader: "Упс, вы находитесь на территории, где ХрюХрюКар пока еще не работает =(",
  violationTypesNotFoundText: "Вы можете это исправить, заполнив форму на добавление территории.",
  violationTypesNotFoundButton: "Заполнить форму",
  placeNotFoundHeader: "Упс, вы находитесь на территории, где ХрюХрюКар не работает =(",
  placeNotFoundText: "Вы можете запустить свой ХрюХрюКар там, где Вы хотите. Ниже ссылка на репозиторий с инструкцией по запуску.",
  placeNotFoundButton: "Запустить свой ХрюХрюКар",
  cameraError: "Ошибка при работе с камерой, напишите разработчику.",
  cameraPermissionDenied: "Доступ к камере запрещен. Пожалуйста, разрешите доступ к камере в настройках браузера.",
  cameraNotFound: "Нам не удалось найти камеру. Убедитесь что вы предоставили разрешение на использование камеры. Минимальное разрешение камеры 960×540 (qHD). Если ошибка повторяется, напишите нам через форму обратной связи (в меню слева).",
  selectCamera: "Выбор камеры (β)",
};

export default hunting;
