const moderatorView = {
  title: "Moderator console",
  overview: "Overview",
  reject: "Reject",
  save: "Save",
  reset: "Reset",
  rejectGeoPhotoModalTitle: "Reject photo reason",
  rejectGeoPhotoModalText: "Attention! This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.",
  rejectCarPlateModalTitle: "Reject car plate reason",
  rejectCarPlateModalText: "Attention! This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.",
  rejectAddressConfirm: "Are you sure you want to reject the address? This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.",
  rejectCarPlateConfirm: "Are you sure you want to reject the car plate? This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.",
  rejectViolationTypeModalTitle: "Reject violation type reason",
  rejectViolationTypeModalText: "Attention! This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.",
  verify: "Verify",
  loading: 'Loading moderation queue...',
  noViolations: 'No violations for moderation',
  close: 'Close',
  confirm: 'Confirm',
  locationAddressAndPanorama: 'Location, address and panorama',
  locationAndAddressConfirmed: 'Location and address confirmed',
  violationTypeConfirmed: 'Violation type confirmed',
  violationTypeRejected: 'Violation type rejected',
  violationTypeChanged: 'Violation type changed',
  plateNumberConfirmed: 'Plate number confirmed',
  plateNumberRejected: 'Plate number rejected',
  plateNumberChanged: 'Plate number changed',
  applicationRejected: 'Application rejected',
  applicationConfirmed: 'Application confirmed',
  rejectApplicationModalTitle: 'Reject application reason',
  rejectApplicationModalText: 'Attention! This action is irreversible. To reject the application, you must specify the reason. After rejection, this application and the violation associated with it will be checked by the administrator.',
  photoRejected: 'Photo successfully rejected',
  changesSaved: 'Changes saved',
  checkAndConfirmAddress: 'Check and confirm address',
  openInYandexMaps: 'Open on Y.Maps',
  carPlate: 'Car plate',
  checkAndConfirmCarPlate: 'Check and confirm car plate',
  confirmAddressConfirm: 'Are you sure you want to confirm the address? This action is irreversible. If you confirm, you will move on to the next step and will not be able to return to this one.',
  confirmCarPlateConfirm: 'Are you sure you want to confirm the car plate? This action is irreversible. If you confirm, you will move on to the next step and will not be able to return to this one.',
  additionalOverview: 'Additional overview',
  youCanAddAdditionalOverview: 'You can add an additional overview panorama to the application',
  thisMayBeUsefulForMoreAccurateFixationOfLocationOrForUnderstandingThePresenceOfCertainFactorsNotVeryNoticeableInWinter: 'This may be useful for more accurate fixation of location or for understanding the presence of certain factors not very noticeable in winter.',
  addPano: 'Add',
  loadingPano: 'Loading...',
  remove: 'Remove',
  skip: 'Skip',
  panoAndMapsNotAvailable: 'Panoramas are not available for this address',
  saveOrResetChangesPlease: 'Save or reset changes',
  noViolationsToModerate: 'No violations for moderation',
  photoOfViolation: 'Photo of violation',
  panorama: 'Panorama',
  violationType: 'Violation type',
  rejectViolationTypeConfirm: 'Are you sure you want to reject the violation type? This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.',
  confirmViolationTypeConfirm: 'Are you sure you want to confirm the violation type? This action is irreversible. If you confirm, an administrative offense report will be drawn up and you will move on to the next step and will not be able to return to this one.',
  checkAndConfirmViolationType: 'Check and confirm violation type',
  moreInfo: 'More info',
  selectViolationType: 'Select violation type',
  send: 'Send',
  rejectApplicationConfirm: 'Are you sure you want to reject the application? This action is irreversible. If you reject, you will move on to the next violation and will not be able to return to this one.',
  confirmApplicationConfirm: 'Are you sure you want to confirm the application? This action is irreversible. If you confirm, an administrative offense report will be sent to the authority for consideration and you will move on to the next violation and will not be able to return to this one.',
  openInNexTab: 'Open in new tab',
  generateAndSendApplication: 'Generate and send application',
  generatingApplication: 'Generating application, please wait...',
  applicationGeneratedAndReadyToSend: 'The application has been generated and is ready to send:',
  current: 'Current',
  select: 'Select',
  enterRejectReason: 'Enter the reason for rejection',
  rejectReasonInvalidFeedback: 'The reason for rejection must be from 5 to 255 characters',

  // reject reasons
  rejectReasonText_blurred: 'Photo is blurred',
  rejectReasonText_faces: 'Faces on photo',
  rejectReasonText_no_car: 'No car',
  rejectReasonText_car_not_fit: 'Car does not fit',
  rejectReasonText_car_plate_not_readable: 'Car plate is not readable',
  rejectReasonText_international_car_plate: 'International car plate',
  rejectReasonText_violation_type_not_confirmed: 'Violation type cannot be confirmed',
  rejectReasonText_violation_already_exists: 'Violation already exists',
  rejectReasonText_geolocation_error: 'Geolocation error',
  rejectReasonText_flood: 'Flood',
  rejectReasonText_adult: 'Adult content',
  rejectReasonText_other: 'Other',
  rejectReasonText_timeout: 'Too much time has passed',
  rejectReasonText_encoding_error: 'Problems with encoding',
  rejectReasonText_moderation_error: 'Moderation performed incorrectly',
  rejectReasonText_layout_error: 'Problems with layout',
  rejectReasonText_applicant_error: 'Error in applicant data',
  rejectReasonText_authority_error: 'Error in authority data',
};

export default moderatorView;
