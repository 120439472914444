// If user can't access some component, we must reload the page
import {setupInterceptors} from "@/api/api";

// If user can't access some component, we must reload the page
window.addEventListener('vite:preloadError', (event) => {
  window.location.reload();
});

import VueCookies from 'vue3-cookies'
import "mdb-vue-ui-kit/css/mdb.min.css";
import "@/ui/assets/styles/global.scss";

import { createPinia } from 'pinia'

import OpenLayersMap, { type Vue3OpenlayersGlobalOptions, } from "vue3-openlayers";
import { createApp } from "vue";

const olOptions: Vue3OpenlayersGlobalOptions = {
  debug: false,
};

const pinia = createPinia();

import App from "./App.vue";

const app = createApp(App);

setupInterceptors();

app.use(VueCookies, { expireTimes: "7d", secure: true, sameSite: "Strict" })

import i18n from "./i18n/vue-i18n";

app.use(i18n)
app.use(OpenLayersMap, olOptions)
app.use(pinia)

import router from "./router";

import * as Sentry from "@sentry/vue";

Sentry.init({
  app,
  dsn: "https://5f840b6e10a12e15a067d055ac6023f3@o4506712643076096.ingest.us.sentry.io/4506712648646656",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/xxkap\.app\/api\/v1/,
    /^https:\/\/xn----7sba2bdrgbnmid4o\.xn--p1ai\/api\/v1/,
    /^https:\/\/dev\.xxkap\.app\/api\/v1/,
    /^https:\/\/staging\.xxkap\.app\/api\/v1/
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router)
app.mount("#app");
