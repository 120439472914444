<template>
  <MDBStepperStep :dynamic='true'>
    <MDBStepperHead>
      <template #icon>
        <MDBIcon fas icon="map-marked-alt" />
      </template>
      {{ $t('moderatorView.locationAddressAndPanorama') }}
    </MDBStepperHead>
    <MDBStepperContent>
      <div class='vstack gap-1 mt-0 pt-0'>
        <MDBCard class='console-card'>
          <MDBCardHeader class='m-0 p-0 rounded-top-2 border-0'>
            <div class='map-and-pano-wrapper' :style="'display:' + (panoramaAndMapsAvailable? 'flex':'none')">
              <div class="map-pano-container" :class="{ 'flex-horizontal': moderationStore.isHorizontalLayout }">
                <ol-map
                  :loadTilesWhileAnimating="true"
                  :loadTilesWhileInteracting="true"
                  class="ol-map-block map-wrapper"
                >
                  <!--
                    Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
                    the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
                    open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
                    the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
                    make changes to this source code to eliminate the use of non-free components.
                  -->
                  <MDBLightbox v-if='srcPhoto && !moderationStore.violationModerationQueueInProgress'>
                    <MDBLightboxItem
                      v-if="srcPhoto"
                      :src="srcPhoto"
                      :fullScreenSrc="srcPhoto"
                      :alt="$t('moderatorView.photoOfViolation')"
                      className='thumbnail-photo w-100 shadow-1-strong rounded'
                    />
                  </MDBLightbox>
                  <ol-view
                    ref="view"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    :projection='"EPSG:3857"'
                  />
                  <ol-tile-layer>
                    <ol-source-xyz ref="sourceRef" :url="tileLayerUrl"/>
                  </ol-tile-layer>
                  <ol-vector-layer>
                    <ol-source-vector>
                      <ol-interaction-modify @modifyend='pointMoved'/>
                      <ol-feature>
                        <ol-geom-point :coordinates="point"></ol-geom-point>
                        <ol-style>
                          <ol-style-icon src="/pig-on-map.png" :scale="0.05"></ol-style-icon>
                          <ol-style-text :text="moderationStore.currentViolationForModeration?.carPlate.plateNumber" offsetY='20'></ol-style-text>
                        </ol-style>
                      </ol-feature>
                    </ol-source-vector>
                  </ol-vector-layer>
                </ol-map>
                <div class='panorama-wrapper' ref='gpano'>
                  <MDBBtn :color="panoramaShot ? 'danger' : 'primary'" floating size="lg" class="panorama-save-btn" @click='loadOrDeletePanorama'>
                    <MDBSpinner v-if='panoramaLoadingInProgress' tag="span" grow size="sm"/>
                    <MDBIcon v-else iconStyle="fas" size="lg" :icon="panoramaShot ? 'trash-alt' : 'save'"/>
                  </MDBBtn>
                  <!--
                    Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
                    the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
                    open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
                    the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
                    make changes to this source code to eliminate the use of non-free components.
                  -->
                  <MDBLightbox v-if='panoramaShot && !moderationStore.violationModerationQueueInProgress'>
                    <MDBLightboxItem
                       v-if="panoramaShot"
                      :src="panoramaShot"
                      :fullScreenSrc="panoramaShot"
                      :alt="$t('moderatorView.panorama')"
                      className='thumbnail-photo w-100 shadow-1-strong rounded'
                    />
                  </MDBLightbox>
                </div>
              </div>
            </div>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBCardText>
              <MDBTextarea
                :label="$t('moderatorView.checkAndConfirmAddress')"
                rows="3"
                v-model="address"
                :helper="hasLocationAddressOrPanoramaChanged ? $t('moderatorView.saveOrResetChangesPlease') : ''"
              />
            </MDBCardText>
            <div class='hstack gap-2 pt-2'>
              <MDBBtn color="secondary" size="sm" :disabled='!hasLocationAddressOrPanoramaChanged' @click='resetAddressLocationOrPanoramaChanges'>
                <MDBIcon iconStyle="fas" icon="redo"></MDBIcon>
              </MDBBtn>
              <MDBBtn color="primary" size="sm" class='me-auto' :disabled='!hasLocationAddressOrPanoramaChanged' @click='saveAddressLocationOrPanoramaChanges'>
                <MDBIcon iconStyle="fas" icon="save"></MDBIcon>
              </MDBBtn>
              <MDBBtn color="link" tag="a" target="_blank" :href="`https://yandex.ru/maps/?ll=${newPointWGS[0]}%2C${newPointWGS[1]}&mode=search&sll=${newPointWGS[0]}%2C${newPointWGS[1]}&text=${newPointWGS[1]}%2C${newPointWGS[0]}&z=18`" size="sm" class='ms-auto'>
                {{ $t('moderatorView.openInYandexMaps')}}
              </MDBBtn>
            </div>
          </MDBCardBody>
          <MDBCardFooter class='p-2'>
            <div class='hstack gap-2'>
              <MDBBtn color="danger" @click="ifaceStore.showRejectReasonSelectorModal" class='me-auto'>
                <i class="fas fa-ban me-2" />
                {{ $t('moderatorView.reject') }}
              </MDBBtn>
              <!--
                Warning about the use of a non-free component. Attention! The MDBPopconfirm component is provided
                under the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as
                part of open-source software. To run the "frontend" app of the OinkOinkCar system, you must either
                purchase the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing)
                or make changes to this source code to eliminate the use of non-free components.
              -->
              <MDBPopconfirm
                class="btn-success ms-auto"
                position="top left"
                :message="$t('moderatorView.confirmAddressConfirm')"
                :cancelText="$t('moderatorView.cancel')"
                :confirmText="$t('moderatorView.confirm')"
                :disabled='hasLocationAddressOrPanoramaChanged'
                @confirm='confirmAddressAndLocation'
              >
                <i class="fas fa-check-square me-2"></i>
                {{ $t('moderatorView.confirm') }}
              </MDBPopconfirm>
            </div>
          </MDBCardFooter>
        </MDBCard>
      </div>
    </MDBStepperContent>
  </MDBStepperStep>
</template>
<script  lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardText, MDBIcon, MDBLightbox, MDBLightboxItem, MDBPopconfirm, MDBSpinner, MDBStepperContent, MDBStepperHead, MDBStepperStep, MDBTextarea } from 'mdb-vue-ui-kit'
import { useModerationStore } from '@/store/moderation'
import { useIfaceStore } from '@/store/iface'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import i18n from '@/i18n/vue-i18n'
import { useCookies } from 'vue3-cookies'
import { useLocaleStore } from '@/store/locale'
import { Loader } from '@googlemaps/js-api-loader'
import { ModifyEvent } from 'ol/interaction/Modify'
import { convertCoordinates, convertCoordinatesBack } from '@/helpers/functions'
import { GeoPhotoModerationPatch } from '@/store/models/geophoto.model'
import { useAppStore } from '@/store/app'
import { MODERATION_STEP } from '@/helpers/enums/moderation-step'
import { ViolationForModeration } from '@/store/models/violation.model'
import {useMapStore} from "@/store/map";
import type XYZ from "ol/source/XYZ";


export default  defineComponent({
  name: "ModerationStepLocationCheckContainer",
  components: {
    MDBSpinner,
    MDBPopconfirm,
    MDBTextarea,
    MDBCardText,
    MDBLightboxItem,
    MDBCardHeader,
    MDBStepperStep,
    MDBLightbox,
    MDBCardFooter,
    MDBStepperHead,
    MDBIcon,
    MDBStepperContent,
    MDBCardBody,
    MDBCard,
    MDBBtn
  },
  computed: {
    hasLocationAddressOrPanoramaChanged() {
      return this.address != this.moderationStore.currentViolationForModeration?.carPlate?.photo?.address || this.pointHasBeenMoved || !this.panoramaShotSaved;
    },
    pointHasBeenMoved() {
      return this.pointWGS[0] != this.newPointWGS[0] || this.pointWGS[1] != this.newPointWGS[1];
    },
  },
  methods: {
    loadOrDeletePanorama() {
      if(this.panoramaShot) {
        this.clearPanorama();
        return;
      }
      const self = this;
      const urlToBase64 = (url) =>
        new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = reject;
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        });

      const setPanorama = (img_b64) => {
        if (img_b64) {
          this.panoramaShot = img_b64;
          this.panoramaShotSaved = false;
        } else {
          self.clearPanorama();
        }
        this.panoramaLoadingInProgress = false;
      };

      if (this.panoramaAndMapsAvailable) {
        this.panoramaLoadingInProgress = true;
        this.panoramaShot = '';
        urlToBase64(`https://maps.googleapis.com/maps/api/streetview?size=640x640&fov=${180 / Math.pow(2, this.panorama.getZoom())}&heading=${this.panorama.getPov().heading}&pitch=${this.panorama.getPov().pitch}&location=${this.panorama.getPosition().lat()},${this.panorama.getPosition().lng()}&key=${this.GOOGLE_MAPS_API_KEY}`)
          .then(setPanorama)
          .catch(() => {
            self.clearPanorama();
            self.panoramaLoadingInProgress = false;
          });
      } else {
        this.clearPanorama();
        this.panoramaLoadingInProgress = false;
      }
    },
    pointMoved(event: ModifyEvent) {
      // @ts-ignore
      const newPoint = event.features.item(0).getGeometry().getCoordinates()
      this.newPointWGS = convertCoordinatesBack(newPoint[0], newPoint[1]);
      this.setNewPoint(this.newPointWGS);
    },
    resetAddressLocationOrPanoramaChanges() {
      this.newPointWGS = this.pointWGS;
      this.setNewPoint(this.newPointWGS);
      this.point = convertCoordinates(this.pointWGS[0], this.pointWGS[1]);
      this.address = this.moderationStore.currentViolationForModeration?.carPlate.photo.address
      this.clearPanorama();
    },
    saveAddressLocationOrPanoramaChanges() {
      let changes: GeoPhotoModerationPatch = {
        address: this.address,
        geom: {
          type: "Point",
          coordinates: this.newPointWGS,
        },
      }
      if (!this.panoramaShotSaved) {
        changes.panoramaShot = this.panoramaShot ? this.panoramaShot : null;
      }
      this.moderationStore.patchCurrentGeoPhotoDuringModeration(changes).then(() => {
        this.panoramaShotSaved = true;
        this.appStore.addSuccessMessage(this.$t('moderatorView.changesSaved'), true, 2000);
      });
    },
    confirmAddressAndLocation() {
      const changes: GeoPhotoModerationPatch = {
        addressConfirmed: true,
        moderationStatus: MODERATION_STATUS.CONFIRMED,
      }
      this.moderationStore.patchCurrentGeoPhotoDuringModeration(changes).then(() => {
          this.appStore.addSuccessMessage(this.$t('moderatorView.locationAndAddressConfirmed'), true, 2000);
          this.moderationStore.chooseNextModerationStep();
      });
    },
    clearPanorama() {
      this.panoramaShot = this.currentViolationForModeration?.carPlate.photo.panoramaShot;
      this.panoramaShotSaved = !this.panoramaShotSaved;
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const appStore = useAppStore();
    const moderationStore = useModerationStore();

    function rejectionCallback(reason: MODERATION_STATUS) {
      moderationStore.rejectGeoPhoto(reason);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.photoRejected'), true, 2000);
    }

    function configureStep() {
      if (moderationStore.currentModerationStep === MODERATION_STEP.LOCATION_CHECK) {
        ifaceStore.setupRejectReasonSelectorModal(
          [
            MODERATION_STATUS.REJECTED_REASON_GEOLOCATION,
            MODERATION_STATUS.REJECTED_REASON_FLOOD,
            MODERATION_STATUS.REJECTED_REASON_OTHER,
          ],
          i18n.global.t('moderatorView.rejectGeoPhotoModalTitle'),
          i18n.global.t('moderatorView.rejectGeoPhotoModalText'),
          rejectionCallback,
        );
      }
    }

    // Google Street View
    const { cookies } = useCookies();
    let GOOGLE_MAPS_API_KEY = '';
    if(cookies.isKey('GOOGLE_MAPS_API_KEY')) {
      GOOGLE_MAPS_API_KEY = cookies.get('GOOGLE_MAPS_API_KEY');
    }
    const panoramaAndMapsAvailable = ref(GOOGLE_MAPS_API_KEY !== '');

    const panoramaShot = ref("");
    const srcPhoto = ref("");
    const address = ref("");
    const addressConfirmed = ref(false);
    const point = ref([0, 0]);
    const pointWGS = ref([0, 0]);
    const newPointWGS = ref([0, 0]);
    const mapZoom = ref(17);
    const mapCenter = ref(convertCoordinates(47.791846, 52.005431),);

    const panoramaShotSaved = ref(true);
    const panorama = ref<google.maps.StreetViewPanorama | null>(null)
    let panoramaService = null as  google.maps.StreetViewService | null;
    let panoramaRequest =  null as google.maps.StreetViewLocationRequest | null;
    let carMarkerOnPanorama =  null as google.maps.Marker | null;
    let panoramaSearchRadius = 25;
    const streetViewLoaded = ref(false);
    const panoramaLoadingInProgress = ref(false);

    const gpano = ref(null);
    const view = ref(null);


    // Street View & Map logic
    function findPanorama(){
      if(panoramaService == null) {
        panoramaService = new google.maps.StreetViewService();
      }
      panoramaRequest = {
        location: { lat: newPointWGS.value[1], lng: newPointWGS.value[0] },
        preference: google.maps.StreetViewPreference.NEAREST,
        radius: panoramaSearchRadius,
        source: google.maps.StreetViewSource.OUTDOOR,
      };
      panoramaService.getPanorama(panoramaRequest, (data: google.maps.StreetViewPanoramaData, status: google.maps.StreetViewStatus) => {
        if (status === google.maps.StreetViewStatus.OK) {
          // @ts-ignore
          panorama.value.setPosition(data.location.latLng);

          const heading = google.maps.geometry.spherical.computeHeading(
            data.location.latLng,
            new google.maps.LatLng(newPointWGS.value[1], newPointWGS.value[0])
          );

          panorama.value.setPov({
            heading: heading,
            pitch: -25,
            // @ts-ignore
            zoom: 1,
          });
          panorama.value.setVisible(true);
          panoramaAndMapsAvailable.value = true;
          // TODO: Add marker of view point to the map
        } else {
          panoramaSearchRadius += 10;
          if(panoramaSearchRadius > 200) {
            panoramaAndMapsAvailable.value = false;
            panorama.value.setVisible(false);
          } else {
            setTimeout(() => {
              findPanorama();
            }, 777);
          }
        }
      });
    }

    function setNewPoint(value: number[]) {
      if(streetViewLoaded.value && value && value.length > 1 && value[0] != 0 && value[1] != 0) {
        findPanorama();
      } else if (!streetViewLoaded.value && value && value.length > 1  && value[0] != 0 && value[1] != 0) {
        setTimeout(() => {
          setNewPoint(value);
        }, 250);
        return;
      }
      if (!carMarkerOnPanorama) {
        carMarkerOnPanorama = new google.maps.Marker({
          position: { lat: value[1], lng: value[0] },
          map: panorama.value,
          label: {
            text: moderationStore.currentViolationForModeration?.carPlate?.plateNumber,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
          },
          icon: {
            url: "/pig-on-map.png",
            scaledSize: new google.maps.Size(48, 48),
            labelOrigin: new google.maps.Point(24, 52),
          },
        });
      } else {
        carMarkerOnPanorama.setPosition({ lat: value[1], lng: value[0] });
        carMarkerOnPanorama.setLabel({
          text: moderationStore.currentViolationForModeration?.carPlate?.plateNumber,
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
        });
      }
    }

    watch(() => moderationStore.currentViolationForModeration, (newViolation: ViolationForModeration | null) => {
      if (newViolation) {
        if (panoramaShot.value != newViolation.carPlate?.photo?.panoramaShot) { panoramaShot.value = newViolation.carPlate.photo.panoramaShot; }
        if (srcPhoto.value != newViolation.carPlate?.photo?.srcPhoto) { srcPhoto.value = newViolation.carPlate.photo.srcPhoto; }
        address.value = newViolation.carPlate?.photo?.address;
        addressConfirmed.value = newViolation.carPlate?.photo?.addressConfirmed;
        const newPointVal = convertCoordinates(newViolation.carPlate?.photo?.geom?.coordinates[0],  newViolation.carPlate?.photo?.geom?.coordinates[1]);
        point.value = newPointVal;
        mapCenter.value = newPointVal;
        const newPointValWGS = [newViolation.carPlate?.photo?.geom?.coordinates[0],  newViolation.carPlate?.photo?.geom?.coordinates[1]];
        pointWGS.value = newPointValWGS;
        newPointWGS.value = newPointValWGS;
        setNewPoint(newPointValWGS);
      } else {
        srcPhoto.value = "";
        panoramaShot.value = "";
        address.value = "";
        addressConfirmed.value = false;
        point.value = [0, 0];
        pointWGS.value = [0, 0];
        newPointWGS.value = [0, 0];
        mapCenter.value = convertCoordinates(47.791846, 52.005431);
        mapZoom.value = 17;
      }
    })

    async function initMapAndPanorama() {
      if (!gpano.value) {
        setTimeout(() => {
          initMapAndPanorama();
        }, 500);
        return;
      }
      if(streetViewLoaded.value) {
        return;
      }
      const { StreetViewPanorama } = await google.maps.importLibrary("streetView") as google.maps.StreetViewLibrary;
      panorama.value = new StreetViewPanorama(
          gpano.value as HTMLElement,
          {
            panControl: true,
            disableDefaultUI: true,
            fullscreenControl: false,
            imageDateControl: true,
            linksControl: false,
            showRoadLabels: true,
            addressControl: false,
            motionTracking: false,
          });
      streetViewLoaded.value = true;
      if(newPointWGS.value[0] != 0 && newPointWGS.value[1] != 0) {
        setNewPoint(newPointWGS.value);
      }
    }

    watch(() => moderationStore.currentModerationStep, (value) => {
      if (value === MODERATION_STEP.LOCATION_CHECK) {
        configureStep();
      }
    });


    const mapStore = useMapStore();
    const sourceRef = ref<{ source: XYZ }>(null);
    const tileLayerUrl = ref(mapStore.tileLayerUrl);

    watch(() => mapStore.tileLayerUrl, (newVal) => {
      sourceRef.value?.source?.setUrl(newVal);
      tileLayerUrl.value = newVal;
    });

    onMounted(() => {
      configureStep();

      // Init Google Maps
      if(GOOGLE_MAPS_API_KEY){
        const localeStore = useLocaleStore();
        const loader = new Loader({
          apiKey: GOOGLE_MAPS_API_KEY,
          version: "weekly",
          language: localeStore.currentLocale,
        });

        loader.load().then(async () => {
          await initMapAndPanorama();
        });
      }

    });

    return {
      sourceRef,
      tileLayerUrl,
      appStore,
      ifaceStore,
      moderationStore,
      panoramaAndMapsAvailable,
      GOOGLE_MAPS_API_KEY,
      panoramaShot,
      srcPhoto,
      address,
      addressConfirmed,
      point,
      pointWGS,
      newPointWGS,
      mapCenter,
      mapZoom,
      panoramaLoadingInProgress,
      gpano,
      view,
      panoramaShotSaved,
      panorama,
      panoramaService,
      carMarkerOnPanorama,
      streetViewLoaded,
      setNewPoint,
    }
  },
});
</script>
<style lang="scss" scoped>
.map-pano-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.map-wrapper {
  min-width: 21rem;
  min-height: 21rem;
  flex-grow: 1;
  position: relative;
}

.ol-map-block {
  min-height: 21rem;
  min-width: 21rem;
}

.flex-horizontal {
  flex-direction: row !important;
  height: 50vh;
}

.panorama-wrapper {
  min-width: 21rem;
  min-height: 21rem;
  flex-grow: 4;
  position: relative;
}

.panorama-save-btn {
  position: absolute;
  z-index: 200;
  top: 4px;
  right: 4px;
}

.thumbnail-photo {
  width: 80px !important;
  height: 80px !important;
  position: absolute;
  z-index: 200;
  left: 4px;
  bottom: 4px;
  border: 2px solid white;
  object-fit: cover;
}

</style>
