const moderatorView = {
  title: "Консоль модератора",
  overview: "Общий обзор",
  cancel: "Отмена",
  reject: "Отклонить",
  save: "Сохранить",
  reset: "Сбросить",
  rejectGeoPhotoModalTitle: "Причина отклонения фотографии",
  rejectGeoPhotoModalText: "Внимание! Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому и другим, связанным с этим фото.",
  rejectCarPlateModalTitle: "Причина отклонения номера автомобиля",
  rejectCarPlateModalText: "Внимание! Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.",
  rejectAddressConfirm: "Вы уверены, что хотите отклонить адрес? Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.",
  rejectCarPlateConfirm: "Вы уверены, что хотите отклонить номер автомобиля? Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.",
  rejectViolationTypeModalTitle: "Причина отклонения типа нарушения",
  rejectViolationTypeModalText: "Внимание! Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.",
  verify: "Проверить",
  loading: 'Загрузка очереди модерации...',
  noViolations: 'Нет нарушений для модерации',
  close: 'Закрыть',
  confirm: 'Подтвердить',
  locationAddressAndPanorama: 'Местоположение, адрес и панорама',
  locationAndAddressConfirmed: 'Местоположение и адрес подтверждены',
  violationTypeConfirmed: 'Тип нарушения подтвержден',
  violationTypeRejected: 'Тип нарушения отклонен',
  violationTypeChanged: 'Тип нарушения изменен',
  plateNumberConfirmed: 'Номер автомобиля подтвержден',
  plateNumberRejected: 'Номер автомобиля отклонен',
  plateNumberChanged: 'Номер автомобиля изменен',
  applicationRejected: 'Заявление отклонено',
  applicationConfirmed: 'Заявление подтверждено',
  rejectApplicationModalTitle: 'Причина отклонения заявления',
  rejectApplicationModalText: 'Внимание! Это действие необратимо. Для отклонения заявления необходимо выбрать причину. После отклонения это заявление и связанное с ним нарушение будут проверены администратором.',
  photoRejected: 'Фотография успешно отклонена',
  changesSaved: 'Изменения сохранены',
  checkAndConfirmAddress: 'Проверьте и подтвердите адрес',
  openInYandexMaps: 'Открыть на Я.Картах',
  carPlate: 'Автомобильный номер',
  checkAndConfirmCarPlate: 'Проверьте и подтвердите номер автомобиля',
  confirmAddressConfirm: 'Вы уверены, что хотите подтвердить адрес? Это действие необратимо. В случае подтверждения вы перейдёте к следующему шагу и не сможете вернуться к этому.',
  confirmCarPlateConfirm: 'Вы уверены, что хотите подтвердить номер автомобиля? Это действие необратимо. В случае подтверждения вы перейдёте к следующему шагу и не сможете вернуться к этому.',
  additionalOverview: 'Дополнительная панорама',
  youCanAddAdditionalOverview: 'Вы можете добавить к заявлению дополнительную обзорную панораму',
  thisMayBeUsefulForMoreAccurateFixationOfLocationOrForUnderstandingThePresenceOfCertainFactorsNotVeryNoticeableInWinter: 'Это может быть полезно для более точной фиксации местоположения или для понимания наличия определенных факторов, мало заметных в зимнее время года.',
  addPano: 'Добавить',
  loadingPano: 'Загрузка...',
  remove: 'Удалить',
  skip: 'Пропустить',
  panoAndMapsNotAvailable: 'Панорамы недоступны для этого адреса',
  saveOrResetChangesPlease: 'Сохраните или сбросьте изменения',
  noViolationsToModerate: 'Нет нарушений для модерации',
  photoOfViolation: 'Фото нарушения',
  panorama: 'Панорама',
  violationType: 'Тип нарушения',
  rejectViolationTypeConfirm: 'Вы уверены, что хотите отклонить тип нарушения? Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.',
  confirmViolationTypeConfirm: 'Вы уверены, что хотите подтвердить тип нарушения? Это действие необратимо. В случае подтверждения будет составлено заявление об административном правонарушении и вы перейдёте к следующему шагу и не сможете вернуться к этому.',
  checkAndConfirmViolationType: 'Проверьте и подтвердите тип нарушения',
  moreInfo: 'Подробнее',
  selectViolationType: 'Выберите тип нарушения',
  send: 'Отправить',
  rejectApplicationConfirm: 'Вы уверены, что хотите отклонить заявление? Это действие необратимо. В случае отклонения вы перейдёте к следующему нарушению и не сможете вернуться к этому.',
  confirmApplicationConfirm: 'Вы уверены, что хотите подтвердить заявление? Это действие необратимо. В случае подтверждения заявление об административном правонарушении будет направлено в уполномоченный орган для рассмотрения и вы перейдёте к следующему нарушению и не сможете вернуться к этому.',
  openInNexTab: 'Открыть в новой вкладке',
  generateAndSendApplication: 'Генерация и отправка заявления',
  generatingApplication: 'Генерация заявления, ждите...',
  applicationGeneratedAndReadyToSend: 'Заявление сгенерировано и готово к отправке:',
  current: 'Текущий',
  select: 'Выбрать',
  enterRejectReason: 'Введите причину отклонения',
  rejectReasonInvalidFeedback: 'Причина отклонения должна быть от 5 до 255 символов',

  // reject reasons
  rejectReasonText_blurred: 'Фото размыто',
  rejectReasonText_faces: 'Лица на фото',
  rejectReasonText_no_car: 'Нет автомобиля',
  rejectReasonText_car_not_fit: 'Авто не видно полностью',
  rejectReasonText_car_plate_not_readable: 'Номер автомобиля не читается',
  rejectReasonText_international_car_plate: 'Номер автомобиля не российский',
  rejectReasonText_violation_type_not_confirmed: 'Тип нарушения не подтверждается',
  rejectReasonText_violation_already_exists: 'Нарушение уже существует',
  rejectReasonText_geolocation_error: 'Проблемы геолокации',
  rejectReasonText_flood: 'Флуд',
  rejectReasonText_adult: 'Запрещенный контент',
  rejectReasonText_other: 'Другое',
  rejectReasonText_timeout: 'Прошло слишком много времени',
  rejectReasonText_encoding_error: 'Проблемы с кодировкой',
  rejectReasonText_moderation_error: 'Модерация выполнена некорректно',
  rejectReasonText_layout_error: 'Проблемы с макетом (с вёрсткой)',
  rejectReasonText_applicant_error: 'Ошибка в данных заявителя',
  rejectReasonText_authority_error: 'Ошибка в данных получателя',
};

export default moderatorView;
